import { createContext } from "react"

export type TokenContent = {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
}

export const TokenContext = createContext<TokenContent>({
  token: "!!!", // set a default value
  setToken: () => {},
});
