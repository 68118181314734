import * as z from "zod";

export const SignUpFormSchema = z.object({
  userProfileID: z.number(),
  userID: z.number(),
  companyName: z
    .string()
    .min(3, {
      message: "Company name is required. Minimum length is 3 characters",
    })
    .max(36, {
      message: "Maximum length is 36 characters.",
    })
    .regex(
      new RegExp(/^[a-zA-Z\s]+$/),
      "Company name cannot contain special characters."
    ),
  jobTitle: z
    .string()
    .min(2, {
      message: "Job title is required. Minimum length is 2 characters",
    })
    .max(40, {
      message: "Maximum length is 40 characters.",
    }),
  firstName: z
    .string()
    .trim()
    .min(3, {
      message: "First name is required. Minimum length is 3 characters",
    })
    .max(20, {
      message: "Maximum length is 20 characters.",
    })
    .regex(new RegExp(/^[a-zA-Z\s]+$/), "Invalid format for firstname"),
  lastName: z
    .string()
    .trim()
    .min(1, {
      message: "Last name is required.",
    })
    .max(20, {
      message: "Maximum length is 20 characters.",
    })
    .regex(new RegExp(/^[a-zA-Z\s]+$/), "Invalid format for lastname"),
  phoneNumber: z.string().min(5, { message: "Phone number is required." }),
  businessEmail: z.string().trim().email("Invalid email address").max(40, {
    message: "Maximum length is 40 characters.",
  }),
});

export const UpdateProfileFormSchema = z.object({
  userProfileID: z.number(),
  userID: z.number(),
  companyName: z
    .string()
    .min(3, {
      message: "Company name is required. Minimum length is 3 characters",
    })
    .max(36, {
      message: "Maximum length is 36 characters.",
    }),
  jobTitle: z
    .string()
    .min(2, {
      message: "Job title is required. Minimum length is 2 characters",
    })
    .max(40, {
      message: "Maximum length is 40 characters.",
    }),
  firstName: z
    .string()
    .min(3, {
      message: "First name is required. Minimum length is 3 characters",
    })
    .max(20, {
      message: "Maximum length is 20 characters.",
    })
    .regex(new RegExp(/^[a-zA-Z\s]+$/), "Invalid format for firstname"),
  lastName: z
    .string()
    .min(1, {
      message: "Last name is required.",
    })
    .max(20, {
      message: "Maximum length is 20 characters.",
    })
    .regex(new RegExp(/^[a-zA-Z\s]+$/), "Invalid format for lastname"),
  phoneNumber: z.string().min(5, { message: "Phone number is required." }),
});

export type SignUpFormType = z.infer<typeof SignUpFormSchema>;
export type UpdateProfileFormType = z.infer<typeof UpdateProfileFormSchema>;
