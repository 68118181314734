import Axios from "axios";

export const axios = Axios.create({
  baseURL: "/api",
});

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token")?.toString();
    if (token) {
      config.headers!.authorization = `Bearer ${token}`;
    }
    config.headers!.Accept = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.status === 4) {
      // toast.error(response.data.message, toastOptions);
      // throw response.data.message;
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;

    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // window.history.pushState(null, "", urlParams.toString());
      // window.location.assign("/Login"); // Logout if unauthorized
    }
    // toast.error(message, toastOptions);

    return Promise.reject(error);
  }
);
