import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { TokenContext } from "../Context/TokenContext";
import { CustomRoutePropsType } from "../Types/CustomRoutePropsType";
import ResponseType from "../Types/ResponseType";
import { RouteConfigType } from "../Types/RouteConfigType";
import PublicRoutes from "./Routes/PublicRoutes";

import { Layout as PublicLayout } from "./Layout/Public/Layout";
import jwt_decode from "jwt-decode";
import {
  RolePageSectionPrivilegeTokenType,
  TokenDecodedModelType,
  TokenModelType,
  UserPagePrivilegeType,
} from "../Types/TokenType";
import { useNavigate } from "react-router-dom";

type AuthType = {
  token: string;
  redirectToHome: (token: string) => void;
  logout: () => void;
  hasToken: () => boolean;
  userAccess: TokenModelType | null;
  routeConfig: RouteConfigType;
  refresh: () => void;
};

const AuthContext = createContext<AuthType | null>(null);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const tokenDecoder = (token: string): TokenModelType | null => {
    try {
      var decodedToken = jwt_decode<TokenDecodedModelType>(token);
      return {
        ...decodedToken,
        rolePagePrivileges: JSON.parse(decodedToken.rolePagePrivileges),
        rolePageSectionPrivileges: JSON.parse(
          decodedToken.rolePageSectionPrivileges
        ),
        userGroup: JSON.parse(decodedToken.userGroup),
      };
    } catch (error) {
      return null;
    }
  };
  const handleAuthenticatedRouteConfig = (token: string): RouteConfigType => {
    const decodedToken = tokenDecoder(token);
    var routes: CustomRoutePropsType[] = PublicRoutes;
    if (decodedToken !== null) {
      routes = routes.filter((x) => {
        if (x.pageID !== undefined) {
          return decodedToken.rolePagePrivileges.filter(
            (privileges) => privileges.PageId === x.pageID && privileges.View
          ).length > 0
            ? x
            : null;
        }
        return x;
      });
    }
    const layout: JSX.Element = <PublicLayout routes={routes} />;

    return { Routes: routes, Layout: layout };
  };

  const { setToken } = useContext(TokenContext);
  const [userToken, setUserToken] = useState("");
  const [userAccess, setUserAccess] = useState<TokenModelType | null>(null);
  const [routeConfiguration, setRouteConfiguration] = useState(
    handleAuthenticatedRouteConfig("")
  );

  const redirectToHome = (token: string) => {
    localStorage.setItem("token", token);
    setUserToken(token);
    setUserAccess(tokenDecoder(token));
    setRouteConfiguration(handleAuthenticatedRouteConfig(token));
    navigate("/home");
    //window.location.href = "/Home";
  };
  const logout = () => {
    localStorage.removeItem("token");
    setToken("");
    setUserAccess(tokenDecoder(""));
    setRouteConfiguration(handleAuthenticatedRouteConfig(""));
    setUserToken("");
    navigate("/");
  };
  const handleHasToken = () => {
    return userToken && userToken.length > 0 ? true : false;
  };
  const value = useMemo(() => {
    return {
      token: userToken,
      redirectToHome,
      logout,
      hasToken: handleHasToken,
      userAccess: userAccess,
      routeConfig: routeConfiguration,
      refresh: () => {},
    };
  }, [userToken]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
