import { ProspectFormType } from "../../../Schemas/ProspectFormSchema";
import { SignUpFormType } from "../../../Schemas/SignUpFormSchema";
import { axios } from "../../../lib/axios";

export const SubmitSignUp = async (data: SignUpFormType) =>
  await fetch("/api/SignUp/Create", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((res) => res.json());

// export const SubmitSignUpDemo = async (data: ProspectFormType) =>
//   await fetch("/api/SignUp/CreateProspect", {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(data),
//   }).then((res) => res.json());

export const SubmitSignUpDemo = (body: ProspectFormType): Promise<any> => {
  return axios.post(`/SignUp/CreateProspect`, body);
};
