import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import "../../media.css";
import { FadingBalls } from "react-cssfx-loading";
import { useAuth } from "../AuthProvider";
import {
  BriefcaseSVG,
  EnvelopeSVG,
  OfficeSVG,
  PhoneSVG,
} from "../Utils/SVGResources";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { SubmitSignUpDemo } from "../SignUp/hooks/actions";
import ResponseType from "../../Types/ResponseType";
import logo from "../../Assets/img/login/dataradar_new_logo_white.png";
import {
  ProspectFormSchema,
  ProspectFormType,
} from "../../Schemas/ProspectFormSchema";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

function SignUpDemo() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [phone, setPhone] = useState("");
  const phoneCountries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["us", "mx", "ca"].includes(iso2);
  });
  function validatePhoneNum(phone: string) {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      var isValid = phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phone)
      );

      clearErrors("phoneNumber");
      if (phone && phone.trim().length > 3) {
        if (!isValid) {
          setError("phoneNumber", {
            type: "validate",
            message: "Invalid phone number",
          });
        }
      }
      return isValid;
    } catch (error) {
      return false;
    }
  }
  const SignUpMutate = useMutation(SubmitSignUpDemo, {
    onSuccess: (res: ResponseType<string>) => {
      if (res.status === 4) {
        setErrorMessage(res.message);
      } else {
        setErrorMessage("");
      }
    },
    onError(error: any) {
      setErrorMessage(error.message);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
    setError,
    watch,
    clearErrors,
  } = useForm<ProspectFormType>({
    mode: "onChange",
    resolver: zodResolver(ProspectFormSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const data = watch();
  const onSubmit = handleSubmit(async (data: ProspectFormType) => {
    //console.log(data);
    if (validatePhoneNum(data.phoneNumber)) {
      SignUpMutate.mutate(data);
    }
  });

  React.useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  const hasError = (error: string | undefined) => {
    return error ? <p className="text-red-500 text-left">* {error}</p> : <></>;
  };
  if (SignUpMutate.isSuccess && SignUpMutate.data?.status !== 4) {
    return (
      <div className="flex flex-col items-center justify-start pt-48 h-screen bg-login-bg bg-no-repeat bg-cover">
        <img
          src={logo}
          alt="DataRadar Logo"
          className="w-1/5 py-8"
          srcSet=""
          onClick={() => {
            navigate("/");
          }}
        />
        <section className=" bg-white rounded-lg  text-gray-900 p-24 space-y-4">
          <h1 className="text-2xl max-w-3xl font-medium">
            Thank you for your request, our team will contact you shortly to
            schedule a demo.
          </h1>
          <h2 className="text-lg max-w-5xl font-medium">
            For further enquires please reach out to{" "}
            <a
              href="mailto:datahappens@dataradar.io"
              className="underline text-blue-600">
              datahappens@dataradar.io
            </a>
          </h2>
        </section>
      </div>
    );
  }
  return (
    <>
      <div className="h-max xl:h-screen bg-login-bg bg-no-repeat bg-cover">
        <div className="flex justify-center flex-col lg:flex-row">
          <div className="flex flex-col text-white lg:pt-24 ">
            <div className="p-6 lg:pr-48 max-w-5xl text-xl text-left">
              <img
                src={logo}
                alt="DataRadar Logo"
                className="w-72"
                srcSet=""
                onClick={() => {
                  navigate("/");
                }}
              />

              <h1 className="font-bold sm:text-3xl text-left py-6 text-rose-500">
                {/* Data Happens… */}
              </h1>
              <h1 className="sm:text-2xl">
                <p>
                  DataRadar is the unique solution to all your data quality,
                  observability, pipeline monitoring, and Cloud data warehouse
                  usage and cost monitoring needs. Start your journey with
                  DataRadar and experience the benefits today.
                </p>
              </h1>
              <div className="hidden lg:block">
                <div className="ml-2 pt-12">
                  <div className="max-w-3xl flex flex-col text-xl sm:text-2xl ">
                    <ul className="ml-12 list-disc space-y-4">
                      <li>
                        Solution Oriented Software to improve trust in data.
                      </li>
                      <li>
                        Pro-active approach for Data Quality, Accuracy &
                        Observability.
                      </li>
                      <li>
                        Integrate seamlessly with Snowflake and provides
                        interactive data pipeline monitoring, utilization
                        insights, cost intelligence, spend forecasting,
                        performance inefficiencies, account health monitoring,
                        and alerts to maximize the return on your snowflake
                        investment.
                      </li>
                    </ul>
                  </div>
                  <h1 className="sm:text-3xl ml-12 pt-10 sm:pt-16 text-left">
                    <div className="">Request your demo today!</div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center lg:justify-start lg:p-6 p-4 lg:pt-24 ">
            <div className="flex flex-col bg-white rounded-lg max-w-xl h-max py-6">
              <div className="sign-up text-center px-12 max-md:px-6">
                <div className="w-full text-blue-900 text-2xl font-bold pt-8 pb-12">
                  See DataRadar in action..
                </div>

                <form
                  onSubmit={onSubmit}
                  className="w-full mx-auto text-center space-y-2">
                  <div className="flex flex-col flex-1">
                    <div className="flex flex-1 items-start gap-x-6">
                      <div className="flex flex-col flex-1 justify-start">
                        <span className="text-left">First Name</span>
                        <span className="relative w-full flex items-center">
                          <input
                            disabled={SignUpMutate.isLoading}
                            {...register("firstName")}
                            required
                            placeholder=""
                            className="form-input border border-gray-400 rounded-lg py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block focus:outline-none"
                          />
                        </span>
                      </div>

                      <div className="flex flex-col flex-1">
                        <span className="text-left">Last Name</span>
                        <span className="relative w-full flex items-center">
                          <input
                            disabled={SignUpMutate.isLoading}
                            {...register("lastName")}
                            required
                            placeholder=""
                            className="form-input border border-gray-400 rounded-lg py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block focus:outline-none"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-span-2">
                      {hasError(errors.firstName?.message ?? "")}
                    </div>
                    <div className="col-span-2">
                      {hasError(errors.lastName?.message ?? "")}
                    </div>
                  </div>

                  <div className="flex flex-1 flex-col items-start gap-y-1">
                    <span className="text-left">Company E-mail</span>
                    <span className="relative w-full text-sm flex items-center">
                      {EnvelopeSVG(
                        "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                      )}
                      <input
                        disabled={SignUpMutate.isLoading}
                        {...register("email")}
                        required
                        placeholder=""
                        className="form-input border border-gray-400 rounded-lg py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                      />
                    </span>
                    {hasError(errors.email?.message)}
                  </div>

                  <div className="flex flex-1 flex-col items-start gap-y-1">
                    <span className="text-left">Phone Number</span>
                    <span className="relative w-full  flex items-center">
                      {PhoneSVG(
                        "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                      )}
                      <PhoneInput
                        value={phone}
                        disabled={SignUpMutate.isLoading}
                        inputClassName="w-full"
                        className="form-input border border-gray-400 rounded-md py-0.5 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                        onChange={(phone) => {
                          validatePhoneNum(phone);
                          setPhone(phone);
                          setValue("phoneNumber", phone);
                        }}
                        countries={phoneCountries}
                        disableDialCodeAndPrefix={true}
                        showDisabledDialCodeAndPrefix={true}
                      />
                    </span>
                    {hasError(errors.phoneNumber?.message)}
                  </div>
                  <div className="flex flex-1 flex-col items-start gap-y-1">
                    <span className="text-left">Company Name</span>
                    <span className="relative w-full  flex items-center">
                      {OfficeSVG(
                        "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                      )}
                      <input
                        disabled={SignUpMutate.isLoading}
                        {...register("companyName")}
                        required
                        placeholder=""
                        className="form-input border border-gray-400 rounded-lg py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                      />
                    </span>
                    {hasError(errors.companyName?.message)}
                  </div>
                  <div className="flex flex-1 flex-col items-start gap-y-1">
                    <span className="text-left">Job Title</span>
                    <span className="relative w-full  flex items-center">
                      {BriefcaseSVG(
                        "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                      )}
                      <input
                        disabled={SignUpMutate.isLoading}
                        {...register("jobTitle")}
                        required
                        placeholder=""
                        className="form-input border border-gray-400 rounded-lg py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                      />
                    </span>
                    {hasError(errors.jobTitle?.message)}
                  </div>
                  {errorMessage && (
                    <div className="flex flex-col text-red-500  text-left">
                      {errorMessage && (
                        <>
                          <span>{errorMessage}</span>
                          <span>
                            For further enquires please reach out to{" "}
                            <a
                              href="mailto:datahappens@dataradar.io"
                              className="underline text-blue-600">
                              datahappens@dataradar.io
                            </a>
                          </span>
                        </>
                      )}
                    </div>
                  )}
                  <div className="py-2">
                    <button
                      disabled={SignUpMutate.isLoading}
                      type="submit"
                      className="text-white px-2 py-2 shadow my-4 rounded-lg w-1/3 mx-auto whitespace-nowrap max-sm:w-auto"
                      style={{ backgroundColor: "#19255A" }}>
                      {SignUpMutate.isLoading ? (
                        <FadingBalls
                          color="#FFFF"
                          width="30px"
                          height="8px"
                          duration="3s"
                          className="m-auto my-2"
                        />
                      ) : (
                        "Request Demo"
                      )}
                    </button>
                  </div>

                  {/*<div className="text-center ">*/}
                  {/*  By submitting your information, you agree to all*/}
                  {/*  <NavLink to={""} className="text-blue-500 px-1">*/}
                  {/*    Terms of Use*/}
                  {/*  </NavLink>*/}
                  {/*  and*/}
                  {/*  <NavLink to={""} className="text-blue-500 px-1">*/}
                  {/*    Privacy Policy{" "}*/}
                  {/*  </NavLink>*/}
                  {/*</div>*/}
                  <div className="text-xs flex justify-center px-4 font-normal lg:font-medium h-12 items-center text-center">
                    © {new Date().getFullYear()} - DataRadar Solutions, Inc. All
                    rights reserved.
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpDemo;
