import { useMutation } from "react-query";
import { axios } from "../../../lib/axios";
import { MutationConfig } from "../../../lib/react-query";
import ResponseType from "./../../../Types/ResponseType";
import { OrgFormType, TenatMainType } from "../Organization";

export const useGetTenant = (
  data: OrgFormType
): Promise<ResponseType<TenatMainType>> => {
  return axios.post(
    "/Authenticate/GetTenantByCompanyName",
    `"${data.companyName}"`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const useUserAuthentication = (props: {
  config?: MutationConfig<typeof useGetTenant>;
}) => {
  return useMutation({
    ...props.config,
    mutationFn: useGetTenant,
  });
};
