import * as React from "react";
import logo from "../../Assets/img/login/dataradar_new_logo_white.png";

export interface IPrivacyPolicyProps {}

export function PrivacyPolicy(props: IPrivacyPolicyProps) {
  return (
    <>
      <div className="flex flex-col items-center justify-start pt-10 h-screen bg-login-bg bg-no-repeat bg-cover w-full">
        <img src={logo} alt="DataRadar Logo" className="w-1/5 py-8" srcSet="" />
        <div className="bg-white rounded-lg w-5/6 h-4/5 overflow-auto">
          <div className="sign-up text-center px-12"></div>
          <div className="w-full text-blue-900 text-4xl font-bold pt-8 pb-8">
            Privacy Policy
          </div>
          {/* Intoduction */}
          <div className=" pl-12 pr-12 text-left">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0  pb-4">
              Introduction
            </div>
            <div className="text-gray-500 text-xl">
              Thank you for using DataRadar Solutions, a dba of CFS Media Group,
              LLC. ("we," "us," or "our") B2B SaaS platform ("Platform"). This
              Privacy Policy explains how we collect, use, disclose, and
              safeguard your personal information when you use our Platform. By
              accessing or using our Platform, you consent to the practices
              described in this Privacy Policy.
            </div>
          </div>
          {/* Information We Collect */}

          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Information We Collect
            </div>
            <div className="w-full text-blue-900 text-xl font-bold pt-0 pb-4">
              2.1 Personal Information
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We may collect personal information that can be used to identify
                you as an individual or contact you.{" "}
              </p>
              <p>The types of personal information we collect may include:</p>{" "}
              <p className="mt-2">
                - Name, job title, and contact details (such as email address,
                phone number, and business address).
              </p>
              <p className="mt-2">
                {" "}
                - Company name and business-related information. Payment
                information for subscription or billing purposes.
              </p>
            </div>
            <div className="w-full text-blue-900 text-xl font-bold pt-4 pb-4">
              2.2 Usage Data
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                When you use our Platform, we may collect certain information
                automatically, including:
              </p>{" "}
              <p className="mt-2">
                - Log files: Information about your device's internet
                connection, IP address, browser type, and operating system.
              </p>
              <p className="mt-2">
                - Usage analytics: Information about your interactions with the
                Platform, such as features accessed, pages visited, and actions
                performed.
              </p>
            </div>
          </div>

          {/* Use Information */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              How We Use Your Information
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We may use the information we collect for various purposes,
                including:
              </p>
              <p className="mt-2">- Providing and maintaining the Platform.</p>
              <p className="mt-2">
                - Communicating with you and responding to your inquiries.
              </p>
              <p className="mt-2">
                - Personalizing and improving the Platform's functionality.
              </p>
              <p className="mt-2">
                - Analyzing usage data to enhance and optimize the Platform.
              </p>
              <p className="mt-2">
                - Protecting against fraud, unauthorized access, or illegal
                activities.
              </p>
              <p className="mt-2">
                - Complying with legal obligations and resolving disputes.
              </p>
            </div>
          </div>

          {/* Info Sharing */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Information Sharing and Disclosure
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We may share your information in the following circumstances:
              </p>
              <p className="mt-2">
                - With third-party service providers and business partners who
                assist us in delivering the Platform's features and services.
              </p>
              <p className="mt-2">
                - With your consent or at your direction, for example, when you
                authorize integration with third-party applications.
              </p>
              <p className="mt-2">
                - In connection with a merger, acquisition, or sale of our
                assets, where your information may be transferred to the new
                entity.
              </p>
              <p className="mt-2">
                - When required by law or to protect our rights, property, or
                safety.
              </p>
            </div>
          </div>

          {/* Data Retention */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Data Retention
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We will retain your personal information for as long as
                necessary to fulfill the purposes outlined in this Privacy
                Policy, unless a longer retention period is required or
                permitted by law.
              </p>
            </div>
          </div>

          {/* Data Security */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Data Security
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We implement industry-standard measures to safeguard your
                personal information from unauthorized access, use, disclosure,
                or alteration. However, please note that no method of
                transmission or storage is completely secure, and we cannot
                guarantee absolute security.
              </p>
            </div>
          </div>

          {/* Your Choices and Rights */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Your Choices and Rights
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                You may have certain rights regarding your personal information,
                including the right to access, update, or delete your
                information. Please contact us using the information provided at
                the end of this Privacy Policy to exercise your rights or if you
                have any questions or concerns.
              </p>
            </div>
          </div>

          {/* Third-Party Links and Services */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Third-Party Links and Services
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                Our Platform may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                content of those third-party websites or services. We encourage
                you to review the privacy policies of any third-party websites
                or services you visit.
              </p>
            </div>
          </div>

          {/* Changes to this Privacy Policy */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Changes to this Privacy Policy
            </div>
            <div className="text-gray-500 text-xl pb-6">
              <p className="mt-2">
                We reserve the right to update or modify this Privacy Policy at
                any time. Any changes will be effective when we post the revised
                Privacy Policy on the Platform. We encourage you to periodically
                review this Privacy Policy for any updates.
              </p>
            </div>
          </div>

          {/* Contact Us */}
          <div className=" pl-12 pr-12 text-left pt-12">
            <div className="w-full text-blue-900 text-2xl font-bold pt-0 pb-4">
              Contact Us
            </div>
            <div className="text-gray-500 text-xl pb-12">
              <p className="mt-2">
                If you have any questions, concerns, or requests regarding this
                Privacy Policy or our privacy practices, please contact us at{" "}
                <a className=" underline text-blue-500">support@dataradar.io</a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
