import { Navigate } from "react-router-dom";
import { CustomRoutePropsType } from "../../Types/CustomRoutePropsType";
import SignUpDemo from "../DemoRequest/DemoRequest";
import SignUp from "./../SignUp/SignUp";
import { PrivacyPolicy } from "../Docs/PrivacyPolicy";
import { TermsOfService } from "../Docs/TermsOfService";
import Organization from "../Login/Organization";
import PageNotFound from "../Error/PageNotFound";
import { AccountSetupGuide } from "../Docs/AccountSetupGuide";
import { TermsAndCondition } from "../Docs/TermsAndConditions";

const PublicRoutes: CustomRoutePropsType[] = [
  {
    index: true,
    path: "/",
    element: <Navigate to="/organization" />,
    isDisplayed: true,
  },
  {
    index: true,
    path: "/organization",
    element: <Organization />,
    isDisplayed: true,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
    isDisplayed: true,
  },
  {
    path: "/request-demo",
    element: <SignUpDemo />,
    isDisplayed: true,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    isDisplayed: true,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndCondition />,
    isDisplayed: true,
  },
  {
    path: "/account-setup-guide",
    element: <AccountSetupGuide />,
    isDisplayed: true,
  },
  {
    path: "*",
    element: <PageNotFound />,
    isDisplayed: true,
  },
];

export default PublicRoutes;
