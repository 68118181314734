import * as React from "react";
import DataRadar_Account_Setup_Guide from "./DataRadar_Account_Setup_Guide.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
import logo from "../../Assets/img/login/dataradar_new_logo_white.png";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export interface IAccountSetupGuideProps {}

export function AccountSetupGuide(props: IAccountSetupGuideProps) {
  const [numPages, setNumPages] = useState(0);

  return (
    <>
      <div className="flex flex-col items-center justify-start h-screen bg-login-bg bg-no-repeat bg-cover w-full">
        <img src={logo} alt="DataRadar Logo" className="w-1/5 py-4" srcSet="" />
        <div className="bg-gray-100  rounded-lg w-[1390px] h-[85vh] max-md:w-auto max-sm:w-auto py-4 overflow-hidden">
          <div className="w-full text-center  text-blue-900 text-3xl font-bold pb-3">
            Account Setup Guide
          </div>
          <iframe
            allowTransparency={true}
            src={`${DataRadar_Account_Setup_Guide}#toolbar=0&zoom=170`}
            className=" w-full h-[76vh] bg-white mb-2"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </div>
      </div>
    </>
  );
}
