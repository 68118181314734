import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function RouteConfig() {
  const auth = useAuth();

  const [routeConfig, setRouteConfig] = useState(auth?.routeConfig);

  useEffect(() => {
    setRouteConfig(auth?.routeConfig);
  }, [auth]);

  return (
    <Routes>
      <Route element={routeConfig?.Layout}>
        {routeConfig?.Routes.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element}>
              {route.children?.map((childRoute, i) => {
                return (
                  <Route
                    key={i}
                    path={childRoute.path}
                    element={childRoute.element}
                  >
                    {childRoute.children?.map((innerChild, i) => {
                      return (
                        <Route
                          key={i}
                          path={innerChild.path}
                          element={innerChild.element}
                        >
                          {innerChild.children?.map((miniChild, i) => {
                            return (
                              <Route
                                key={i}
                                path={miniChild.path}
                                element={miniChild.element}
                              />
                            );
                          })}
                        </Route>
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Route>
    </Routes>
  );
}
export default RouteConfig;
