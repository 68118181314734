import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import logo from "../../Assets/img/login/dataradar_new_logo.png";
import datahappens from "../../Assets/img/login/datahappens.png";
import "../../media.css";
import { FadingBalls } from "react-cssfx-loading";
import { useAuth } from "../AuthProvider";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { TextField } from "../Utils/Form/TextField";
import * as z from "zod";
import { useGetTenant, useUserAuthentication } from "./api/useGetTenant";
import { useMutation } from "react-query";
import poweredBySnowflakeLogo from "../../Assets/img/Powered_By_Lockup_Stacked_Blue.svg";
import poweredBySnowflakeLog2 from "../../Assets/img/powered-by-lockup-2-horizontal.svg";
import bgImage from "../../Assets/img/login/login-background.png";

export interface TenatMainType {
  tenantId: string;
  tenantName: string;
  url: string;
}
export const OrgFormSchema = z.object({
  companyName: z.string().min(1, { message: "Organization name is required." }),
});

export type OrgFormType = z.infer<typeof OrgFormSchema>;
function Organization() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [responseErr, setResponseErr] = useState("");
  const submitMutate = useMutation(useGetTenant, {
    onSuccess: (res) => {
      if (res.status === 4) {
        setResponseErr(res.message);
      } else if (res.status === 2) {
        if (res.responseData.tenantName) {
          setResponseErr("");
          const companyName = res.responseData.tenantName.toLocaleLowerCase();
          localStorage.setItem("companyName", companyName);
          window.location.href = res.responseData.url;
        } else {
          setResponseErr("Organization does not exist.");
        }
      } else {
        setResponseErr(res.message);
      }
    },
    onError(error: any) {
      setResponseErr(error.message);
    },
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isValidating },
    setFocus,
    watch,
  } = useForm<OrgFormType>({
    mode: "onSubmit",
    resolver: zodResolver(OrgFormSchema),
    defaultValues: {
      companyName: localStorage.getItem("companyName") ?? "",
    },
  });
  //const data = watch();
  const onSubmit = handleSubmit(async (data: OrgFormType) => {
    submitMutate.mutate(data);
  });

  React.useEffect(() => {
    setFocus("companyName");
  }, [setFocus]);

  React.useEffect(() => {
    if (!formState.isValid && isValidating) {
      setResponseErr("");
    }
  }, [formState, isValidating]);

  const hasError = (error: string | undefined) => {
    return error ? <p className="text-red-500 text-sm">* {error}</p> : <></>;
  };
  return (
    <div
      className="flex justify-center h-screen"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }}>
      <div className="flex-initial lg:flex-1 lg:flex-col  hidden lg:flex pl-40 pr-20 py-20 justify-between">
        <div>
          <img src={datahappens} className="w-[50vh]"></img>
        </div>
        <div className="flex flex-col justify-start">
          <div className="text-5xl text-left py-5 text-white mt">
            Start Your Data Quality
          </div>
          <div className="text-5xl text-left py-5 text-white pb-16">
            Journey With Us!
          </div>
          {/* <Link
            to="/request-demo"
            className="text-xl text-left text-white bg-red-500 w-max p-3 rounded-lg font-medium">
            Request a demo today
          </Link> */}
        </div>
        <div></div>
      </div>
      <div className="flex-initial my-auto lg:pr-40">
        <div className=" bg-white shadow-lg rounded-lg p-8 text-left">
          <div className="w-full grow pb-20">
            <div className="flex justify-center pt-8 pb-12 max-w-md mx-auto w-72">
              <img src={logo} className="" alt="Data radar" />
            </div>
            <form
              onSubmit={onSubmit}
              className="flex flex-col gap-6 max-w-md mx-auto text-left">
              <div className="text-center ">
                <label className="text-sm font-medium ">
                  Enter Organization Name
                </label>
                <input
                  disabled={IsLoading}
                  {...register("companyName")}
                  required
                  placeholder="Enter Organization Name"
                  className="placeholder-gray-300 w-full py-2.5 px-3.5 border-solid border border-gray-300 rounded-md focus:outline-none mt-3 mb-2.5"
                />
                <span className="text-left">
                  {hasError(errors?.companyName?.message ?? responseErr)}
                </span>
              </div>

              <button
                disabled={submitMutate.isLoading}
                type="submit"
                className=" text-white  px-12 py-2.5 shadow rounded-md w-full"
                style={{ backgroundColor: "#19255A" }}>
                {submitMutate.isLoading ? (
                  <FadingBalls
                    color="#FFFF"
                    width="30px"
                    height="8px"
                    duration="3s"
                    className="m-auto my-2"
                  />
                ) : (
                  "Next"
                )}
              </button>

              <p className="text-sm font-medium">
                Don't have an account?{" "}
                <NavLink className="font-bold text-purple-500" to={"/sign-up"}>
                  Sign up
                </NavLink>
              </p>
            </form>

            <div className="mt-20">
              <img
                className="mx-auto"
                src={poweredBySnowflakeLog2}
                alt="powered by Snowflake"
                width={300}
              />
            </div>
          </div>
          <div className="text-sm font-medium w-full text-center">
            &copy; {new Date().getFullYear()} DataRadar Solutions, Inc. All
            rights reserved.{" "}
            <a
              href="../privacy-policy"
              className=" text-blue-400 underline ml-1 cursor-pointer">
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="../terms-and-conditions"
              className=" text-blue-400 underline cursor-pointer">
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
