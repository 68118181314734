import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import React, { useState } from "react";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { AuthProvider, useAuth } from "./Components/AuthProvider";
import RouteConfig from "./Components/Routes/RouteConfig";
import { queryClient } from "./lib/react-query";
import "react-tooltip/dist/react-tooltip.css";

function App() {
  const [selectedDatamartId, setSelectedDatamartId] = useState<number>();

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />

      <div className="App text-gray-600">
        <React.Suspense fallback={<span>Loading...</span>}>
          <Router>
            <AuthProvider>
              <RouteConfig />
            </AuthProvider>
          </Router>
        </React.Suspense>
      </div>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
export default App;
