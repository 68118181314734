import * as React from "react";
import logo from "../../Assets/img/login/dataradar_new_logo_white.png";
import Terms_And_Conditions from "./TermsAndConditions.pdf";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export interface ITermsAndConditionProps {}

export function TermsAndCondition(props: ITermsAndConditionProps) {
  return (
    <div className="flex flex-col items-center justify-start h-screen bg-login-bg bg-no-repeat bg-cover w-full">
      <img src={logo} alt="DataRadar Logo" className="w-1/5 py-4" srcSet="" />
      <div className="bg-gray-100 rounded-lg w-[1390px] h-[85vh] max-md:w-auto max-sm:w-auto py-4 overflow-hidden">
        <div className="sign-up text-center px-12"></div>
        <div className="w-full text-center  text-blue-900 text-3xl font-bold pb-3">
          Terms and Conditions
        </div>
        <div className="w-full h-full">
          <iframe
            allowTransparency={true}
            src={`${Terms_And_Conditions}#toolbar=0&zoom=165`}
            className=" w-full h-[76vh] bg-white mb-2"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            frameBorder={0}
          />
        </div>
      </div>
    </div>
  );
}
