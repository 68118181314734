import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SignUpFormSchema,
  SignUpFormType,
} from "../../Schemas/SignUpFormSchema";

import "../../media.css";
import { FadingBalls } from "react-cssfx-loading";
import { useAuth } from "../AuthProvider";
import {
  BriefcaseSVG,
  EnvelopeSVG,
  OfficeSVG,
  PhoneSVG,
} from "../Utils/SVGResources";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";
import { useMutation } from "react-query";
import { SubmitSignUp } from "./hooks/actions";
import ResponseType from "../../Types/ResponseType";
import logo from "../../Assets/img/login/dataradar_new_logo.png";
import logo2 from "../../Assets/img/login/dataradar_new_logo_white.png";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const toastOptions: ToastOptions<{}> = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function SignUp() {
  const navigate = useNavigate();
  const [invalidNameError, setInvalidNameError] = useState(false);
  const auth = useAuth();
  const [phone, setPhone] = useState("");
  const phoneCountries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["us", "mx", "ca"].includes(iso2);
  });
  const SignUpMutate = useMutation(SubmitSignUp, {
    onSuccess: (
      data: ResponseType<{
        userProfileID: number;
        email: string;
        password: string;
      }>
    ) => {
      if (data.responseData.userProfileID !== 0 && data.status === 2) {
        //localStorage.setItem("email", data.responseData.email);
        //localStorage.setItem("OTP", data.responseData.password);
        //toast.success("Successfully created the account.", toastOptions);
        //   navigate("/Login");
      } else if (data.status === 4) {
        toast.error(data.message, toastOptions);
      } else {
        toast.error("An error occur.", toastOptions);
        console.log(data);
      }
    },
    onError(error) {
      console.log(error);
      toast.error("Error: " + error, toastOptions);
    },
  });
  function validatePhoneNum(phone: string) {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      var isValid = phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phone)
      );

      clearErrors("phoneNumber");
      if (phone && phone.trim().length > 3) {
        if (!isValid) {
          setError("phoneNumber", {
            type: "validate",
            message: "Invalid phone number",
          });
        }
      }
      return isValid;
    } catch (error) {
      return false;
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<SignUpFormType>({
    mode: "onChange",
    resolver: zodResolver(SignUpFormSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      userProfileID: 0,
      userID: 0,
      phoneNumber: "",
    },
  });

  const data = watch();
  const onSubmit = handleSubmit(async (data: SignUpFormType) => {
    if (!invalidNameError && validatePhoneNum(data.phoneNumber)) {
      SignUpMutate.mutate(data);
    }
  });
  React.useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  React.useEffect(() => {
    if (
      data.firstName?.toLowerCase().includes("test") ||
      data.lastName?.toLowerCase().includes("test")
    ) {
      setInvalidNameError(true);
    } else {
      setInvalidNameError(false);
    }
  }, [data.firstName, data.lastName]);

  const hasError = (error: string | undefined) => {
    return error ? (
      <p className="text-red-500 text-sm text-left">* {error}</p>
    ) : (
      <></>
    );
  };
  if (SignUpMutate.isSuccess && SignUpMutate.data?.status === 2) {
    return (
      <div className="flex flex-col items-center justify-start pt-48 h-screen bg-login-bg bg-no-repeat bg-cover">
        <img
          src={logo2}
          alt="DataRadar Logo"
          className="w-1/5 py-8"
          srcSet=""
          onClick={() => {
            navigate("/");
          }}
        />
        <section className=" bg-white rounded-lg  text-gray-900 p-24 space-y-4">
          <h1 className="text-2xl max-w-3xl font-medium">
            Thank you for your registration, we will review your request and
            send response to the registered email address.
          </h1>
          <h2 className="text-lg max-w-5xl font-medium">
            For further enquires please reach out to{" "}
            <a
              href="mailto:datahappens@dataradar.io"
              className="underline text-blue-600">
              datahappens@dataradar.io
            </a>
          </h2>
        </section>
      </div>
    );
  }
  return (
    <div className="flex flex-1 h-screen">
      <div className="flex flex-1 md:flex-row h-full">
        <div
          className="md:flex lg:flex justify-center md:basis-8/12 lg:basis-1/2 xl:basis-1/2 hidden flex-col p-20"
          style={{ backgroundColor: "#19255A" }}>
          <div className="text-5xl text-left py-5 text-white pb-16">
            Democratize your data with confidence
          </div>
          <div
            className="text-lg font-medium text-justify p-14 rounded-xl"
            style={{ backgroundColor: "white", color: "#19255A" }}>
            DataRadar is an advanced SaaS platform that helps companies meet the
            challenges related to trust, quality, accuracy, transparency and
            data governance, and ensures data teams have full visibility of
            their enterprise data warehouse data pipelines. This product
            integrates seamlessly over cloud and legacy data platforms and
            greatly improves the visibility of your enterprise data warehouse by
            monitoring data quality and accuracy, tracking metadata changes
            across systems, and generating metadata exceptions for user review
            and action.
          </div>
        </div>
        <div
          className="my-auto basis-full md:basis-5/12 lg:basis-1/2 xl:basis-1/2 h-full lg:px-12 xl:px-28"
          style={{ backgroundColor: "#F6F9FC" }}>
          <div className="w-9/12 md:w-10/12 lg:w-10/12 mx-auto lg:px-8 md:px-6 mt-14">
            <img
              src={logo}
              alt="DataRadar Logo"
              className="w-56 mx-auto"
              srcSet=""
              onClick={() => {
                navigate("/");
              }}
            />
            <div className="text-2xl font-bold text-left pt-10 pb-3">
              Sign Up
            </div>
            <hr className="my-3" />
            <form
              onSubmit={onSubmit}
              className="flex flex-1 flex-col gap-y-3 w-100 mt-5">
              <input type="hidden" {...register("userProfileID")} />
              <input type="hidden" {...register("userID")} />
              <div className="flex flex-col flex-1">
                <div className="flex flex-1 items-start gap-x-6">
                  <div className="flex flex-col flex-1 justify-start">
                    <span className="text-left">First Name</span>
                    <span className="relative w-full text-sm flex items-center">
                      <input
                        disabled={SignUpMutate.isLoading}
                        {...register("firstName")}
                        required
                        placeholder=""
                        className="form-input border border-gray-400 rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block focus:outline-none"
                      />
                    </span>
                  </div>
                  <div className="flex flex-col flex-1">
                    <span className="text-left">Last Name</span>
                    <span className="relative w-full text-sm flex items-center">
                      <input
                        disabled={SignUpMutate.isLoading}
                        {...register("lastName")}
                        required
                        placeholder=""
                        className="form-input border border-gray-400 rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block focus:outline-none"
                      />
                    </span>
                  </div>
                </div>
                {hasError(
                  invalidNameError ? "Name  must not have test data." : ""
                )}
                {hasError(errors.lastName?.message)}
                {hasError(errors.firstName?.message)}
              </div>
              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-left">Business E-mail</span>
                <span className="relative w-full text-sm flex items-center">
                  {EnvelopeSVG(
                    "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                  )}
                  <input
                    disabled={SignUpMutate.isLoading}
                    {...register("businessEmail")}
                    required
                    placeholder=""
                    className="form-input border border-gray-400 rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                  />
                </span>
                {hasError(errors.businessEmail?.message)}
              </div>
              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-left">Phone Number:</span>
                <span className="relative w-full text-sm flex items-center">
                  {PhoneSVG(
                    "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                  )}
                  <PhoneInput
                    value={phone}
                    disabled={SignUpMutate.isLoading}
                    inputClassName="w-full"
                    className="form-input rounded-md py-0.5 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                    onChange={(phone) => {
                      validatePhoneNum(phone);
                      setPhone(phone);
                      setValue("phoneNumber", phone);
                    }}
                    countries={phoneCountries}
                    disableDialCodeAndPrefix={true}
                    showDisabledDialCodeAndPrefix={true}
                  />
                </span>
                {hasError(errors.phoneNumber?.message)}
              </div>
              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-left">Company Name</span>
                <span className="relative w-full text-sm flex items-center">
                  {OfficeSVG(
                    "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                  )}
                  <input
                    disabled={SignUpMutate.isLoading}
                    {...register("companyName")}
                    required
                    placeholder=""
                    className="form-input border border-gray-400 rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                  />
                </span>
                {hasError(errors.companyName?.message)}
              </div>
              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-left">Job Title</span>
                <span className="relative w-full text-sm flex items-center">
                  {BriefcaseSVG(
                    "pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3 text-black"
                  )}
                  <input
                    disabled={SignUpMutate.isLoading}
                    {...register("jobTitle")}
                    required
                    placeholder=""
                    className="form-input border border-gray-400 rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-8 focus:outline-none"
                  />
                </span>
                {hasError(errors.jobTitle?.message)}
              </div>
              <button
                disabled={SignUpMutate.isLoading}
                type="submit"
                className="text-white px-2 py-2 shadow my-4 rounded-md w-1/3 mx-auto whitespace-nowrap max-sm:w-auto"
                style={{ backgroundColor: "#19255A" }}>
                {SignUpMutate.isLoading ? (
                  <FadingBalls
                    color="#FFFF"
                    width="30px"
                    height="8px"
                    duration="3s"
                    className="m-auto my-2"
                  />
                ) : (
                  "Get Started"
                )}
              </button>
              <div className="text-left text-sm">
                By signing up you are creating a Dataradar account, and you
                agree to all
                <NavLink
                  to={"/terms-of-service"}
                  className="text-blue-500 px-1">
                  Terms of Service{" "}
                </NavLink>{" "}
              </div>
              <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5"></div>
              <div className="text-center text-md">
                Already have an account?{" "}
                <NavLink
                  to={"/organization"}
                  className="text-purple-500 underline">
                  Sign in
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
